import React, { ReactNode } from "react";

interface FadeInProps {
  children: ReactNode;
  delay?: number; // Delay before animation starts, in seconds
  duration?: number; // Animation duration, in seconds
  className?: string; // Additional classes for styling
}

const FadeInTop: React.FC<FadeInProps> = ({
  children,
  delay = 0,
  duration = 1,
  className = "",
}) => {
  return (
    <div
      className={`fade-in-top ${className}`}
      style={{
        animationDelay: `${delay}s`,
        animationDuration: `${duration}s`,
      }}
    >
      {children}
    </div>
  );
};

export default FadeInTop;

