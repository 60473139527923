import React, { useState } from "react";
import SideNav from "../Components/SideNav";
import ProfileNav from "../Components/ProfileNav";
import { useNavigate } from "react-router-dom";
import plus from "../assets/outline_plus.svg";
import ValuesCard from "../Components/ValuesCard";
import fi from "../assets/fi.svg";
import vfi from "../assets/vfi.svg";
import pfi from "../assets/pfi.svg";
import arc from "../assets/arc.svg";
import FadeInAnimation from "../Components/FadeIn";
import c1 from "../assets/c1.png";
import c2 from "../assets/c2.png";
import c3 from "../assets/c3.png";
import c4 from "../assets/c4.png";
import c5 from "../assets/c5.png";
import g1 from "../assets/g1.png";
import g2 from "../assets/g2.png";
import g3 from "../assets/g3.png";
import g4 from "../assets/g4.png";
import g5 from "../assets/g5.png";
import dropdownicon from "../assets/icon-park_down.svg";
import LineChart from "../Components/LineChart";
import emptyList from "../assets/empty-list.svg";
import emptyChart from "../assets/empty-chart.svg";

function Dashboard() {
  const navigate = useNavigate();
  const loaderArr = [1, 2, 3, 4];
  const cloadArr = [1, 2, 3, 4, 5, 6];
  const [loading1, setLoading1] = useState(false);
  const [cload, setCLoad] = useState(false);
  const [activeValue, setActiveValue] = useState("Last 14 days");
  const [data, setData] = useState<any>([
    {
      id: 1,
      project: "Cityscape Living",
      category: "Real estate",
      status: "Funding open",
      dateListed: "02-07-2024",
      fundingGoal: "$10,000",
      progress: "25%",
      deadline: "02-12-2024",
      img: c1,
      gImg: g1,
    },
    {
      id: 2,
      project: "Truffle Skin",
      category: "Beauty",
      status: "Failed to fund",
      dateListed: "01-01-2024",
      fundingGoal: "$30,000",
      progress: "0%",
      deadline: "01-09-2024",
      img: c2,
      gImg: g2,
    },
    {
      id: 3,
      project: "Mawi",
      category: "Entertainment",
      status: "In progress",
      dateListed: "02-05-2024",
      fundingGoal: "$20,000",
      progress: "80%",
      deadline: "31-12-2024",
      img: c3,
      gImg: g3,
    },
    {
      id: 4,
      project: "Wellness Hub",
      category: "Healthcare",
      status: "Funding open",
      dateListed: "06-07-2024",
      fundingGoal: "$7,000",
      progress: "0%",
      deadline: "02-12-2024",
      img: c5,
      gImg: g4,
    },
    {
      id: 5,
      project: "FutureLab Hub",
      category: "Technology",
      status: "Drafts",
      dateListed: "07-10-2024",
      fundingGoal: "$15,000",
      progress: "80%",
      deadline: "02-12-2024",
      img: c4,
      gImg: g5,
    },
    
  ]);
  return (
    <div className="dash-body">
      <SideNav activeTab="Dashboard" />
      <div className="content-body">
        <ProfileNav />
        <div className="section-ph">
          <div className="ph-text-sec">
            <h4>Dashboard</h4>
            <p>Real-time overview of your listed projects</p>
          </div>
          <div
            className="lnp-btn"
            onClick={() => {
              navigate("/ListProjectPromtScreen");
            }}
          >
            <img src={plus} alt="" /> List new project
          </div>
        </div>
        <div className="dash-overview">
          <div className="card-holder">
            <div className="holder1">
              <ValuesCard
                extraClass={"item-126"}
                delay={0.5}
                text1="All projects"
                icon={fi}
                bottomComp={
                  <>
                    <p className="count-value">0 listed</p>
                  </>
                }
              />

              <ValuesCard
                delay={1}
                extraClass={"item-126"}
                text1="Brand vouchers"
                icon={vfi}
                bottomComp={
                  <>
                    <p className="count-value">0 listed</p>
                  </>
                }
              />
            </div>
            <div className="holder2">
              <ValuesCard
                delay={1.5}
                text1="Investor count"
                icon={pfi}
                bottomComp={
                  <>
                    <p className="count-value">0 investors</p>
                  </>
                }
              />
              <ValuesCard
                delay={2}
                text1="Funding progress"
                icon={arc}
                bottomComp={
                  <div>
                    <p className="count-value v1">0%</p>
                    <div className="custom-slider">
                      <div className="track" style={{ width: `${25}%` }}>
                        <div className="thumb">
                          <div className="thumb-inner"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <div className="chart-holder">
            <FadeInAnimation duration={1} delay={1.5}>
              <div className="ch-header">
                <p>Funds raised</p>
                <div className="drop-btn">
                  {activeValue} <img src={dropdownicon} alt="" />
                </div>
              </div>
              {data.length === 0 || cload ? (
                <></>
              ) : (
                <div className="ch-header2">
                  <p>$1,520.50</p>
                  {data.length > 1 && (
                    <div className="active-crbs">
                      {data.map((item: any, index: number) => {
                        const itemWidth = `${100 / data.length}%`;
                        return (
                          <div className="crbs">
                            <div
                              style={{
                                width: 12,
                                height: 3,
                                marginRight: 10,
                                backgroundColor:
                                  index === 0
                                    ? "#683FEA"
                                    : index === 1
                                    ? "#A7FC00"
                                    : index === 2
                                    ? "#FC7900"
                                    : index === 3
                                    ? "#FFD700"
                                    : index === 4
                                    ? "#C3B2F7"
                                    : "#A7FC00",
                              }}
                            ></div>
                            <span
                              style={{
                                overflow: "hidden", // Hide overflowing text
                                whiteSpace: "nowrap", // Prevent wrapping
                                textOverflow: "ellipsis", // Show ellipsis for overflow
                                color: "#fff",
                                fontSize: 14,
                                flex: 1, // Allow the text to use remaining space
                              }}
                              title={item.project} // Full text on hover
                            >
                              {item.project}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              <div
                className="main-chart-cont"
                style={{ marginTop: data.length === 0 || cload ? 43 : 33 }}
              >
                {cload ? (
                  <>
                    {cloadArr.map((_: any, ind: number) => (
                      <div
                        className={`cload-dash ${
                          ind === cloadArr.length - 1 ? "" : "bottom-26"
                        }`}
                      ></div>
                    ))}
                  </>
                ) : data.length === 0 ? (
                  <>
                    <div className="empty-wrap" style={{ marginTop: 24 }}>
                      <img src={emptyChart} alt="" />
                      <p>No data Available</p>
                      <span>Your chart will be here</span>
                    </div>
                  </>
                ) : (
                  <LineChart />
                )}
              </div>
            </FadeInAnimation>
          </div>
        </div>
        <div className="lp-cont">
          <div className="lp-header">
            <h4>Listed project</h4>
            <div className="vr-btn">View report</div>
          </div>
          <div className="lp-content-holder">
            {loading1 ? (
              <>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Project</th>
                      <th>Status</th>
                      <th>Date Listed</th>
                      <th style={{ paddingLeft: 10 }}>Funding Goal</th>
                      <th style={{ paddingLeft: 10 }}>Funding Progress</th>
                      <th>Funding Deadline</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {loaderArr.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <div className="tr">
                            <div className="img-load"></div>
                            <div className="tr-text">
                              <div className="t-load"></div>
                              <div className="in-load"></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className={`f-item-load`}></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div className="d-load"></div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div
                              className="prog-bar-cont"
                              style={{ color: "#616161" }}
                            >
                              <div className="prog-bar">
                                <div
                                  className="bar"
                                  style={{
                                    width: "25%",
                                    backgroundColor: "#838383",
                                  }}
                                ></div>
                              </div>
                              <div className="prog-load"></div>
                            </div>
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last">
                            <div className="d-load"></div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : data.length === 0 ? (
              <>
                <div className="empty-wrap">
                  <img src={emptyList} alt="" />
                  <p>No project available</p>
                  <span>List of projects will appear here</span>
                </div>
              </>
            ) : (
              <FadeInAnimation delay={0.5} duration={1}>
                <table className="table">
                  <thead className="th1">
                    <tr>
                      <th>Project</th>
                      <th>Status</th>
                      <th>Date Listed</th>
                      <th style={{ paddingLeft: 10 }}>Funding Goal</th>
                      <th style={{ paddingLeft: 10 }}>Funding Progress</th>
                      <th>Funding Deadline</th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {data.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <div className="tr">
                            <img src={item.img} alt="" />
                            <div className="tr-text">
                              <p>{item.project}</p>
                              <span>{item.category}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">
                            <div
                              className={`f-item ${
                                item.status.includes("Failed")
                                  ? "f-item-error"
                                  : item.status.includes("progress")
                                  ? "f-item-prog"
                                  : item.status.includes("Draft")
                                  ? "f-item-draft"
                                  : ""
                              }`}
                            >
                              {item.status}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="tr1">{item.dateListed}</div>
                        </td>
                        <td className="funding-goal">
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            {item.fundingGoal}
                          </div>
                        </td>
                        <td>
                          <div className="tr1" style={{ paddingLeft: 10 }}>
                            <div
                              className="prog-bar-cont"
                              style={{ color: "#616161" }}
                            >
                              <div className="prog-bar">
                                <div
                                  className="bar"
                                  style={{
                                    width: item.progress,
                                  }}
                                ></div>
                              </div>
                              {item.progress}
                            </div>
                          </div>
                        </td>
                        <td className="ddLine">
                          <div className="tr-last">{item.deadline}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FadeInAnimation>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
