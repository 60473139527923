import React, { useState, useEffect, useRef } from "react";
import sucTick from "../assets/sucTick.svg";
import unSucTick from "../assets/unSucTick.svg";
import tPending from "../assets/tPending.svg";
import tClose from "../assets/tClose.svg";

let showToastFn: any;

export function handleToast(
  text: string,
  type: "success" | "error" | "pending"
) {
  showToastFn(text, type);
}

export default function Toast() {
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [type, setType] = useState("success");
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the timeout

  useEffect(() => {
    // Define the showToast function that will be called to display the toast
    showToastFn = (text: string, type: string) => {
      setText(text);
      setType(type);
      setShow(true);

      // Clear any existing timeout before setting a new one
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setShow(false); // Close toast after 5 seconds
      }, 5000);
    };

    return () => {
      // Clean up the timeout when the component is unmounted
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const closeToast = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the timeout when the toast is manually closed
    }
    setShow(false); // Close the toast
  };

  const capitalizeFirstLetterOfEachSentence = (text: string) => {
    const sentenceEndings = /([.!?])\s*/;
    return text
      .split(sentenceEndings)
      .map((segment, index) => {
        if (index % 2 === 0) {
          return (
            segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase()
          );
        }
        return segment;
      })
      .join("");
  };

  return (
    <div
      className={`toast-container ${show ? "show" : ""}`}
      style={{
        backgroundColor:
          type === "success"
            ? "#EDFECC"
            : type === "error"
            ? "#F5DEE3"
            : "#F0ECFF",
        borderColor:
          type === "success"
            ? "#86CA00"
            : type === "error"
            ? "#E3A8B4"
            : "#B49FF5",
      }}
    >
      <img
        src={type === "error" ? unSucTick : type === "success" ? sucTick : tPending}
        alt={type === "error" ? "Error Icon" : type === "success" ? "Success Icon" : "Pending Icon"}
        className="icon"
      />
      <p className="toast-header-text" style={{ color: "#000000" }}>
        {text}
      </p>
      <button className="toast-close-btn" onClick={closeToast}>
        <img src={tClose} alt="Close" />
      </button>
    </div>
  );
}
