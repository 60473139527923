import React, { useEffect, useState } from "react";

interface PProps {
  sentences: string[];
  speed?: number;
  pause?: number;
}

const TyperAnimation = ({ sentences, speed = 100, pause = 1000 }: PProps) => {
  const [displayText, setDisplayText] = useState("");
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false); // Indicates if text is being erased
  const [showCursor, setShowCursor] = useState(false); // For cursor visibility
  const [isBlinking, setIsBlinking] = useState(false); // For blinking effect
  const [animationStarted, setAnimationStarted] = useState(false); // Controls the initial delay

  useEffect(() => {
    // Set initial delay before starting animation
    const initialDelayTimer = setTimeout(() => {
      setAnimationStarted(true);
    }, 3000); // 3.5 seconds
    return () => clearTimeout(initialDelayTimer);
  }, []);

  useEffect(() => {
    if (!animationStarted) return; // Skip animation logic until delay completes

    const currentSentence = sentences[sentenceIndex];

    if (!isDeleting && charIndex < currentSentence.length) {
      // Typing characters
      setShowCursor(false); // Hide cursor while typing
      const typeTimer = setTimeout(() => {
        setDisplayText((prev) => prev + currentSentence[charIndex]);
        setCharIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(typeTimer);
    } else if (!isDeleting && charIndex === currentSentence.length) {
      // Sentence completed, show cursor immediately and start blinking
      setShowCursor(true);
      setIsBlinking(true); // Start blinking
      const pauseTimer = setTimeout(() => {
        setIsDeleting(true);
        setIsBlinking(false); // Stop blinking during erasing
      }, pause);
      return () => clearTimeout(pauseTimer);
    } else if (isDeleting && charIndex > 0) {
      // Erasing characters
      setShowCursor(true); // Keep cursor visible while erasing
      const eraseTimer = setTimeout(() => {
        setDisplayText((prev) => prev.slice(0, -1));
        setCharIndex((prev) => prev - 1);
      }, speed / 2); // Faster deletion
      return () => clearTimeout(eraseTimer);
    } else if (isDeleting && charIndex === 0) {
      // Move to the next sentence
      setIsDeleting(false);
      setShowCursor(false); // Hide cursor when starting a new sentence
      setSentenceIndex((prev) => (prev + 1) % sentences.length); // Loop to first sentence
    }
  }, [animationStarted, charIndex, isDeleting, sentenceIndex, sentences, speed, pause]);

  useEffect(() => {
    let blinkInterval: NodeJS.Timeout | null = null;
    if (isBlinking) {
      blinkInterval = setInterval(() => {
        setShowCursor((prev) => !prev); // Toggle cursor visibility
      }, 300); // Blink every 300ms
    }
    return () => {
      if (blinkInterval) clearInterval(blinkInterval); // Cleanup on unmount
    };
  }, [isBlinking]);

  return (
    <div className="typer">
      {displayText}
      {showCursor && <span>|</span>}
    </div>
  );
};
export default TyperAnimation;
