import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Chart, // Import Filler for background effect
} from "chart.js";

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // Register Filler for background effect
);

const LineChart = () => {
  Chart.defaults.font.family = "Figtree";

  const projects = [
    { name: "Cityscape living", data: [1000, 500, 1500] },
    { name: "Truffel skin", data: [2000, 200, 1200] },
    { name: "Mawi", data: [500, 1500, 1000] },
    { name: "Wellness Hub", data: [0, 1600, 0] },
    { name: "FutureLab Hub", data: [1500, 100, 1400] },
  ];

  const datasets = projects.map((project, index) => ({
    label: project.name,
    data: project.data,
    borderColor: ["#683FEA", "#A7FC00", "#FC7900", "#FFD700", "#C3B2F7"][index % 5],
    backgroundColor: projects.length === 1 ? "rgba(104, 63, 234, 0.1)" : "transparent",
    borderWidth: 1.5,
    tension: 0,
    pointRadius: 0,
    pointHoverRadius: 5,
    fill: true
  }));

  // Chart data
  const data = {
    labels: ["Sep 7", "Sep 11", "Sep 16"], // X-axis labels
    datasets,
  };
  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#A5A5A5", // X-axis text color
          font: {
            size: 14, // Font size for X-axis labels
            weight: "400", // Font weight for X-axis labels
          },
        },
        grid: {
          display: false, // Hide gridlines
        },
        padding: 30,
      },
      y: {
        ticks: {
          color: "#A5A5A5", // Y-axis text color
          font: {
            size: 14, // Font size for Y-axis labels
            weight: "400", // Font weight for Y-axis labels
          },
          callback: function (value: any) {
            return value / 1000 + "k"; // Format Y-axis ticks (e.g., 2k, 1k)
          },
          stepSize: 1000, // Custom step size for Y-axis
        },
        min: 0, // Start the Y-axis at 0
        max: 2000, // Highest Y-axis value
        grid: {
          color: "rgba(255, 255, 255, 0.1)", // Gridline color
        },
      },
    },
  };

  return (
    <div style={{ height: 140, width: "100%" }}>
      {/* @ts-ignore */}
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
