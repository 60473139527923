import React from "react";
import envelop from "../assets/envelop.gif";
import SecBtn from "../Components/SecBtn";
import { useNavigate } from "react-router-dom";

export default function EmailSentScreen() {
  const navigate = useNavigate();
  return (
    <section className="emailBody">
      <img src={envelop} alt="" className="envelop" />
      <div className="textWrap">
        <h4>Email sent</h4>
        <p>
          Thank you for contacting our tokenization team. We’ll get back
          <br />
          to you shortly to schedule a consultation call.
        </p>
      </div>
      <div className="btn-Wrap">
        <SecBtn
          text="Back to home"
          onPress={() => {
            navigate("/");
          }}
        />
      </div>
    </section>
  );
}
