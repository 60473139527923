import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import FadeInAnimation from "../Components/FadeIn";
import checkmark from "../assets/checkmark.svg";

export default function WhatIsTokenizationScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const mapData = [
    {
      title: "List 1",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: 2.5,
    },
    {
      title: "List 2",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: 3,
    },
    {
      title: "List 3",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: 3.5,
    },
    {
      title: "List 4",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: 4,
    },
    {
      title: "List 5",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: 4.5,
    },
    {
      title: "List 6",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: 5,
    },
  ];
  return (
    <div className="tkbody">
      <NavBar currentActive="What is Tokenization" />

      <FadeInAnimation delay={1.5} duration={1}>
        <h4 className="bannerText bt">What is Tokenization?</h4>
      </FadeInAnimation>

      <div className="list-item-cont">
        {mapData.map((item, index) => (
          <FadeInAnimation delay={item.delay} duration={1}>
            <div className="list-card">
              <img src={checkmark} alt="" />
              <h4>{item.title}</h4>
              <p>{item.text}</p>
            </div>
          </FadeInAnimation>
        ))}
      </div>
    </div>
  );
}
