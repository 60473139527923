import React, { useEffect, useState } from "react";
import SideNav from "../../Components/SideNav";
import ProfileNav from "../../Components/ProfileNav";
import plus from "../../assets/outline_plus.svg";
import search from "../../assets/search.svg";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import { ReactComponent as List } from "../../assets/list.svg";
import { ReactComponent as Grid } from "../../assets/grid.svg";
import FadeInTop from "../../Components/FadeInTop";
import c1 from "../../assets/c1.png";
import c2 from "../../assets/c2.png";
import c3 from "../../assets/c3.png";
import c4 from "../../assets/c4.png";
import c5 from "../../assets/c5.png";
import g1 from "../../assets/g1.png";
import g2 from "../../assets/g2.png";
import g3 from "../../assets/g3.png";
import g4 from "../../assets/g4.png";
import g5 from "../../assets/g5.png";
import FadeInAnimation from "../../Components/FadeIn";
import emptyList from "../../assets/empty-list.svg";
import { useNavigate } from "react-router-dom";

function ProjectScreen() {
  const [projects, setProjects] = useState<any>([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isListActive, setIsListActive] = useState(true);
  const [gridActive, setGridActive] = useState(false);
  const [sExpanded, setSExpanded] = useState(false);
  const [animatedWidth, setAnimatedWidth] = useState("0");
  const [loading, setLoading] = useState(false);
  const loaderArr = [1, 2, 3, 4, 5];
  const navigate = useNavigate();
  const [data, setData] = useState<any>([
    {
      id: 1,
      project: "Cityscape Living",
      category: "Real estate",
      status: "Funding open",
      dateListed: "02-07-2024",
      fundingGoal: "$10,000",
      progress: "25%",
      deadline: "02-12-2024",
      img: c1,
      gImg: g1,
    },
    {
      id: 2,
      project: "Truffle Skin",
      category: "Beauty",
      status: "Failed to fund",
      dateListed: "01-01-2024",
      fundingGoal: "$30,000",
      progress: "0%",
      deadline: "01-09-2024",
      img: c2,
      gImg: g2,
    },
    {
      id: 3,
      project: "Mawi",
      category: "Entertainment",
      status: "In progress",
      dateListed: "02-05-2024",
      fundingGoal: "$20,000",
      progress: "80%",
      deadline: "31-12-2024",
      img: c3,
      gImg: g3,
    },
    {
      id: 4,
      project: "Wellness Hub",
      category: "Healthcare",
      status: "Funding open",
      dateListed: "06-07-2024",
      fundingGoal: "$7,000",
      progress: "0%",
      deadline: "02-12-2024",
      img: c5,
      gImg: g4,
    },
    {
      id: 5,
      project: "FutureLab Hub",
      category: "Technology",
      status: "Drafts",
      dateListed: "07-10-2024",
      fundingGoal: "$15,000",
      progress: "80%",
      deadline: "02-12-2024",
      img: c4,
      gImg: g5,
    },
    // Add more data as needed
  ]);

  const onList = () => {
    setIsListActive(true);
    setGridActive(false);
  };
  const onGrid = () => {
    setGridActive(true);
    setIsListActive(false);
  };
  useEffect(() => {
    const expandTimeout = setTimeout(() => {
      setSExpanded(true);
    }, 500);

    // Return a cleanup function
    return () => clearTimeout(expandTimeout);
  }, []);

  return (
    <div className="dash-body">
      <SideNav activeTab="Projects" />
      <div className="content-body">
        <ProfileNav />
        <div className="section-ph">
          <div className="ph-text-sec">
            <h4>Projects</h4>
            <p>Take a tour of your listed projects</p>
          </div>
          <div
            className="lnp-btn"
            onClick={() => {
              navigate("/ListProjectPromtScreen");
            }}
          >
            <img src={plus} alt="" /> List new project
          </div>
        </div>
        {data.length > 0 && (
          <div className={`mega-search ${sExpanded ? "sExpanded" : ""}`}>
            <FadeInTop delay={1} duration={1} className="search-nim">
              <div className="s-wrap">
                <img src={search} alt="" />
                <input type="text" placeholder="Search project by name" />
              </div>
            </FadeInTop>

            <div className="actions-wrap">
              <FadeInTop delay={1.5} duration={1}>
                <div
                  className={`filter ${isFilterActive ? "action-active" : ""}`}
                >
                  <Filter style={{ stroke: "currentColor" }} />
                  Filter
                </div>
              </FadeInTop>
              <FadeInTop delay={2} duration={1}>
                <div
                  className={`action-icon ${
                    isListActive ? "action-active" : ""
                  } icon1`}
                  onClick={onList}
                >
                  <List style={{ stroke: "currentColor" }} />
                </div>
              </FadeInTop>
              <FadeInTop delay={2.5} duration={1}>
                <div
                  className={`action-icon ${gridActive ? "action-active" : ""}`}
                  onClick={onGrid}
                >
                  <Grid style={{ stroke: "currentColor" }} />
                </div>
              </FadeInTop>
            </div>
          </div>
        )}

        <div
          className={`pr-list-body ${
            data.length === 0 || loading ? "new-pr-height" : ""
          }`}
        >
          {loading ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>Project</th>
                    <th>Status</th>
                    <th>Date Listed</th>
                    <th style={{ paddingLeft: 10 }}>Funding Goal</th>
                    <th style={{ paddingLeft: 10 }}>Funding Progress</th>
                    <th>Funding Deadline</th>
                  </tr>
                </thead>
                <tbody className="tBody">
                  {loaderArr.map((item: any) => (
                    <tr key={item.id}>
                      <td>
                        <div className="tr">
                          <div className="img-load"></div>
                          <div className="tr-text">
                            <div className="t-load"></div>
                            <div className="in-load"></div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="tr1">
                          <div className={`f-item-load`}></div>
                        </div>
                      </td>
                      <td>
                        <div className="tr1">
                          <div className="d-load"></div>
                        </div>
                      </td>
                      <td className="funding-goal">
                        <div className="tr1" style={{ paddingLeft: 10 }}>
                          <div className="d-load"></div>
                        </div>
                      </td>
                      <td>
                        <div className="tr1" style={{ paddingLeft: 10 }}>
                          <div
                            className="prog-bar-cont"
                            style={{ color: "#616161" }}
                          >
                            <div className="prog-bar">
                              <div
                                className="bar"
                                style={{
                                  width: "25%",
                                  backgroundColor: "#838383",
                                }}
                              ></div>
                            </div>
                            <div className="prog-load"></div>
                          </div>
                        </div>
                      </td>
                      <td className="ddLine">
                        <div className="tr-last">
                          <div className="d-load"></div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              {data.length === 0 ? (
                <>
                  <div className="empty-wrap">
                    <img src={emptyList} alt="" />
                    <p>No project available</p>
                    <span>List of projects will appear here</span>
                  </div>
                </>
              ) : (
                <div
                  className={`content ${
                    gridActive ? "grid-view" : "list-view"
                  }`}
                >
                  {gridActive ? (
                    // Render grid view
                    data.map((item: any) => {
                      return (
                        <div key={item.id} className="grid-card">
                          <FadeInAnimation>
                            <div className="wrap-sec1">
                              <img src={item.gImg} alt="" />
                              <div className="wrap-sec1-text">
                                <div className="tr-text">
                                  <p>{item.project}</p>
                                  <span>{item.category}</span>
                                </div>
                                <div
                                  className={`f-item ${
                                    item.status.includes("Failed")
                                      ? "f-item-error"
                                      : item.status.includes("progress")
                                      ? "f-item-prog"
                                      : item.status.includes("Draft")
                                      ? "f-item-draft"
                                      : ""
                                  }`}
                                  style={{ marginTop: 8 }}
                                >
                                  {item.status}
                                </div>
                              </div>
                            </div>
                            <div className="div" style={{ marginTop: 12 }}>
                              <div className="grid-t-list">
                                <p>Date Listed:</p>
                                <p> {item.dateListed}</p>
                              </div>
                              <div className="grid-t-list mTop12">
                                <p>Funding Progress:</p>
                                <div
                                  className="prog-bar-cont"
                                  style={{ color: "#616161" }}
                                >
                                  <div className="prog-bar grid-prog-bar">
                                    <div
                                      className="bar"
                                      style={{
                                        // width: item.progress,
                                        width: item.progress, // Bind to animated width state
                                        transition: "width 1.5s ease-in-out",
                                      }}
                                    ></div>
                                  </div>
                                  {item.progress}
                                </div>
                              </div>
                              <div className="grid-t-list mTop12">
                                <p>Funding Goal:</p>
                                <p>{item.fundingGoal}</p>
                              </div>
                            </div>
                          </FadeInAnimation>
                          <div className="view-d">View Details</div>
                        </div>
                      );
                    })
                  ) : (
                    // Render list (table) view
                    <FadeInAnimation delay={0.5} duration={1}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Project</th>
                            <th>Status</th>
                            <th>Date Listed</th>
                            <th style={{ paddingLeft: 10 }}>Funding Goal</th>
                            <th style={{ paddingLeft: 10 }}>
                              Funding Progress
                            </th>
                            <th>Funding Deadline</th>
                          </tr>
                        </thead>
                        <tbody className="tBody">
                          {data.map((item: any) => (
                            <tr key={item.id}>
                              <td>
                                <div className="tr">
                                  <img src={item.img} alt="" />
                                  <div className="tr-text">
                                    <p>{item.project}</p>
                                    <span>{item.category}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tr1">
                                  <div
                                    className={`f-item ${
                                      item.status.includes("Failed")
                                        ? "f-item-error"
                                        : item.status.includes("progress")
                                        ? "f-item-prog"
                                        : item.status.includes("Draft")
                                        ? "f-item-draft"
                                        : ""
                                    }`}
                                  >
                                    {item.status}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tr1">{item.dateListed}</div>
                              </td>
                              <td className="funding-goal">
                                <div
                                  className="tr1"
                                  style={{ paddingLeft: 10 }}
                                >
                                  {item.fundingGoal}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="tr1"
                                  style={{ paddingLeft: 10 }}
                                >
                                  <div
                                    className="prog-bar-cont"
                                    style={{ color: "#616161" }}
                                  >
                                    <div className="prog-bar">
                                      <div
                                        className="bar"
                                        style={{
                                          width: item.progress,
                                        }}
                                      ></div>
                                    </div>
                                    {item.progress}
                                  </div>
                                </div>
                              </td>
                              <td className="ddLine">
                                <div className="tr-last">{item.deadline}</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </FadeInAnimation>
                  )}
                </div>
              )}
            </>
          )}
          {/* Container that toggles between grid and list views */}
        </div>
      </div>
    </div>
  );
}

export default ProjectScreen;
