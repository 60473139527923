import React, { useState, useEffect } from "react";
import HeaderBar from "../Components/HeaderBar";
import { useNavigate } from "react-router-dom";
import Input from "../Components/Input";
import FadeInAnimation from "../Components/FadeIn";
import SecBtn from "../Components/SecBtn";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  // Email validation function
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    setEmail(value);

    if (validateEmail(value)) {
      setEmailError(""); // Clear error if valid
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handleSubmit = async () => {
    if (!emailError && email) {
      navigate("/FpVerifyEmailScreen", { state: { email } });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="tk-reg-body">
      <HeaderBar
        onclick={() => {
          navigate(-1);
        }}
      />
      <div className="pageCont mTop40">
        <FadeInAnimation delay={0.3} duration={1}>
          <h4 className="pageH4">Forgot password</h4>
        </FadeInAnimation>
        <FadeInAnimation delay={0.6} duration={1}>
          <p className="pageP">
            No worries! We’ll send a 4-digit code to the email associated with
            this account
            <br />
            to verify that it’s you.
          </p>
        </FadeInAnimation>
        <div className="inputCont"></div>
        <div className="inputCont1">
          <FadeInAnimation delay={0.9} duration={1}>
            <Input
              type="text"
              label="Email address"
              value={email}
              onChange={handleEmailChange}
              error={emailError !== ""}
              errorText={emailError}
            />
          </FadeInAnimation>
        </div>
        <div className="bottomCont">
          <FadeInAnimation delay={1.2} duration={1}>
            <SecBtn
              text="Send code"
              onPress={handleSubmit}
              disabled={emailError !== "" || !email}
            />
          </FadeInAnimation>
        </div>
      </div>
    </div>
  );
}
