import { RequestHandler } from "./Request";

const request = new RequestHandler();
export function TokenizeRequest(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("tbs/request/add", body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function SendOTP(email: string, type: string): Promise<any> {
  return new Promise((resolve, reject) => {
    request.get(`tbs/resendotp?email=${email}&otp-type=${type}`, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function GetIndustries(): Promise<any> {
  return new Promise((resolve, reject) => {
    request.get(`user/get-all-industries`, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function VerifyOTP(otp: number, type: string): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post(`tbs/verify-otp?otp=${otp}&otp-type=${type}`, {}, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function CreateUser(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post(`tbs/create-user`, body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function Login(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post(`tbs/login`, body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function ResetPassword(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post(`tbs/resetPassword`, body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
