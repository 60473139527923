import React from "react";

import chevronBack from "../assets/ion_chevron-back.svg";
import ProgressRing from "./ProgressRing";
interface PProps {
  onclick?: any;
  showProgress?: true | false;
  progressText?: string;
  progress?: number;
}
export default function HeaderBar({
  onclick,
  showProgress,
  progressText,
  progress = 0,
}: PProps) {
  return (
    <div className="headerBar">
      <div onClick={onclick} className="backBtn">
        <img src={chevronBack} alt="" />
        Back
      </div>
      {showProgress && (
        <ProgressRing
          radius={20}
          stroke={3}
          progress={progress}
          text={progressText}
        />
      )}
    </div>
  );
}
