import React from "react";
import search from "../assets/search.svg";
import notification from "../assets/notification.svg";
import profile from "../assets/profile.png";
import FadeInAnimation from "./FadeIn";
import FadeInTop from "./FadeInTop";

interface PProps {
  showSearch?: true | false;
}
function ProfileNav({ showSearch = true }: PProps) {
  return (
    <div className="pr-nav" style={{backgroundColor: "#000"}}>
      <FadeInTop delay={0.5} duration={1}>
        {showSearch ? (
          <div className="custom-input">
            <img src={search} alt="" />
            <input type="text" placeholder="Search" />
          </div>
        ) : (
          <div></div>
        )}
      </FadeInTop>

      <div className="noti-profile">
        <FadeInTop delay={1} duration={1}>
          <div>
            <img src={notification} alt="" />
          </div>
        </FadeInTop>
        <FadeInTop delay={1.5} duration={1}>
          <div>
            <img src={profile} alt="" className="profile-img" />
          </div>
        </FadeInTop>
      </div>
    </div>
  );
}

export default ProfileNav;
