import React, { useState, useRef, useEffect } from "react";
import FadeInAnimation from "../Components/FadeIn";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderBar from "../Components/HeaderBar";
import { SendOTP, VerifyOTP } from "../RequestHandlers/Auth";
import Toast, { handleToast } from "../Components/Toast";

export default function VerifyEmailScreen2() {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(60);
  const [fields, setFields] = useState(Array(4).fill(""));
  const [resendDisabled, setResendDisabled] = useState(true);
  const [error, setError] = useState(false); // Error state for incorrect OTP
  const location = useLocation();
  const email = location.state?.email || "";

  const ref_input1 = useRef();
  const ref_input2 = useRef();
  const ref_input3 = useRef();
  const ref_input4 = useRef();
  const refs = [ref_input1, ref_input2, ref_input3, ref_input4];

  let countdownInterval = useRef<NodeJS.Timeout | null>(null);

  const startCountdown = () => {
    if (countdownInterval.current) {
      clearInterval(countdownInterval.current);
    }

    setResendDisabled(true);
    setSeconds(60);

    countdownInterval.current = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(countdownInterval.current!);
          setResendDisabled(false);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const focusNextField = (index: number) => {
    if (index < refs.length - 1) {
      // @ts-ignore
      refs[index + 1].current.focus();
    }
  };

  const focusPrevField = (index: number) => {
    if (index > 0) {
      // @ts-ignore
      refs[index - 1].current.focus();
    }
  };

  const handleChangeText = (index: number, text: string) => {
    const updatedFields = [...fields];
    updatedFields[index] = text;
    setFields(updatedFields);

    if (text !== "" && index < refs.length - 1) {
      focusNextField(index);
    }

    if (error) setError(false); // Clear error state when user starts typing
  };

  const handleKeyPress = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      event.preventDefault(); // Prevent default backspace behavior
      const updatedFields = [...fields];
      updatedFields[index] = "";
      setFields(updatedFields);

      if (index > 0) {
        focusPrevField(index);
      }
    }
  };

  const sendEmailverify = async () => {
    try {
      const res = await SendOTP(email, "reset");
      console.log("res", res);
      if (res.status === true) {
        // handleToast("OTP sent", "success");
      } else {
        handleToast("Error sendig OTP", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleResend = () => {
    startCountdown();
    sendEmailverify();
  };

  const validateOtp = async (otp: number) => {
    try {
      const res = await VerifyOTP(otp, "reset");
      console.log(res);

      if (res.status === true) {
        navigate("/ResetPassword", { state: { email } });
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (fields.every((field) => field !== "")) {
      validateOtp(Number(fields.join("")));
    }
  }, [fields]);

  useEffect(() => {
    if (email) {
      sendEmailverify();
    }
    startCountdown(); // Start countdown on component mount

    return () => {
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current); // Clean up on unmount
      }
    };
  }, []);
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  return (
    <>
      <Toast />
      <div className="tk-reg-body">
        <HeaderBar
          onclick={() => {
            navigate(-1);
          }}
        />
        <div className="pageCont1">
          <FadeInAnimation delay={0.3} duration={1}>
            <h4 className="pageH4">Verify email address</h4>
          </FadeInAnimation>
          <FadeInAnimation delay={0.6} duration={1}>
            <p className="pageP pageP1">
              Enter the 4-digit code we sent to
              <br />
              <span className="email-span">{email}</span>
            </p>
          </FadeInAnimation>
          <div>
            <FadeInAnimation delay={0.9} duration={1}>
              <div className="otp-cont">
                {refs.map((ref, index) => (
                  <div
                    key={index}
                    className="otp-input"
                    style={{
                      marginRight: index === refs.length - 1 ? 0 : "",
                      borderColor: error
                        ? "#BA2543"
                        : fields[index]
                        ? "#343434"
                        : "",
                    }}
                  >
                    <input
                      type="text"
                      maxLength={1}
                      // @ts-ignore
                      ref={ref}
                      onChange={(e) => handleChangeText(index, e.target.value)}
                      onKeyDown={(e) => handleKeyPress(index, e)}
                      value={fields[index]}
                    />
                  </div>
                ))}
              </div>
            </FadeInAnimation>
            {error && ( // Display error message
              <FadeInAnimation delay={0} duration={1}>
                <p
                  className="error-text"
                  style={{ color: "#BA2543", textAlign: "left" }}
                >
                  Invalid code
                </p>
              </FadeInAnimation>
            )}
          </div>
          <FadeInAnimation delay={1.2} duration={1}>
            <div className="timer-cont">
              {seconds > 0 ? (
                <>
                  <p className="resend-timer">
                    Resend code in{" "}
                    <span className="seconds-timer">{formatTime(seconds)}</span>
                  </p>
                </>
              ) : (
                <>
                  <p
                    className="rsnd"
                    onClick={handleResend}
                    style={{ color: "#A7FC00", fontWeight: "500" }}
                  >
                    Resend code
                  </p>
                </>
              )}
            </div>
          </FadeInAnimation>
        </div>
      </div>
    </>
  );
}
