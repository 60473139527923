import React, { useEffect, useState } from "react";
import "../css/style.css";
import NavBar from "../Components/NavBar";
import FadeInAnimation from "../Components/FadeIn";
import TyperAnimation from "../Components/TyperAnimation";
import PrimaryBtn from "../Components/PrimaryBtn";
import AnalyticImg from "../assets/AnalyticsImg.webp";
import DashboardImg from "../assets/DashboardImg.webp";
import { useNavigate } from "react-router-dom";

export default function HomeScreen() {
  const images = [DashboardImg, AnalyticImg];
  const navigate = useNavigate();
  const typerText = [
    "List your projects on StableBlocks.",
    "Track your projects' success.",
    "Get tools for visibility and funding.",
  ];
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [fadeKey, setFadeKey] = useState(0);

  useEffect(() => {
    // Switch images every 5 seconds
    const interval = setInterval(() => {
      setFadeKey((prevKey) => prevKey + 1);
      setCurrentImage((prevImage) => {
        const nextIndex = (images.indexOf(prevImage) + 1) % images.length;
        return images[nextIndex];
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="body">
      <NavBar currentActive="About TBS" />
      <FadeInAnimation delay={1.5} duration={1}>
        <div className="textCard">
          <p># We help you fund your dreams</p>
        </div>
      </FadeInAnimation>
      <FadeInAnimation delay={2.5} duration={1}>
        <h4 className="bannerText">Welcome to the TX Business Suite.</h4>
      </FadeInAnimation>
      <FadeInAnimation delay={3} duration={1}>
        <TyperAnimation
          sentences={typerText}
          speed={150}
          pause={2000}
          // className="typerText"
        />
      </FadeInAnimation>
      <FadeInAnimation delay={3.5} duration={1}>
        <div className="desTextCont">
          <div className="desTextContInner">
            <p className="desText">
              List and manage your tokenized projects on StableBlocks. TBS is
              your all-in-one tool for managing your listed projects, analyzing
              performance and driving project success.
            </p>
          </div>
        </div>
      </FadeInAnimation>
      <FadeInAnimation delay={4} duration={1}>
        <div className="btnWrap">
          <PrimaryBtn
            text={"Get started"}
            onPress={() => {
              navigate("/SignUpScreen");
            }}
          />
        </div>
      </FadeInAnimation>
      <FadeInAnimation delay={4.5} duration={1}>
        <div className="imgCont">
          <img
            src={currentImage}
            alt="Dashboard"
            className="bannerImg"
            // key={fadeKey}
          />
        </div>
      </FadeInAnimation>
    </div>
  );
}
