import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import WhatIsTokenizationScreen from "./Screens/WhatIsTokenizationScreen";
import TokenizeWithUs from "./Screens/TokenizeWithUs";
import EmailSentScreen from "./Screens/EmailSentScreen";
import SignUpScreen from "./Screens/SignUpScreen";
import VerifyEmailScreen from "./Screens/VerifyEmailScreen";
import SignUpScreen2 from "./Screens/SignUpScreen2";
import CreatePassword from "./Screens/CreatePassword";
import AccountCreatedScreen from "./Screens/AccountCreatedScreen";
import LoginScreen from "./Screens/LoginScreen";
import ForgotPassword from "./Screens/ForgotPasswordScreen";
import VerifyEmailScreen2 from "./Screens/VeriyEmailScreen2";
import ResetPassword from "./Screens/ResetPasswordScreen";
import PasswordResetSuccess from "./Screens/PasswordResetSuccess";
import Cookies from "js-cookie";
import { CredentailsContext } from "./context/CredentailContext";
import Dashboard from "./Screens/Dashboard";
import ProjectScreen from "./Screens/Projects/ProjectScreen";
import ListProjectPromtScreen from "./Screens/Projects/ListProjectPromtScreen";

function App() {
  const [storedCredentails, setStoredCredentails] = useState(null);
  const credentials = Cookies.get("webCook");

  useEffect(() => {
    const credentials = Cookies.get("webCook");
    if (credentials) {
      setStoredCredentails(JSON.parse(credentials));
    }
  }, []);
  // PrivateRoute: Ensures only authenticated users can access certain routes
  const PrivateRoute = ({ children }: any) => {
    return credentials ? children : <Navigate to="/" />;
  };

  // RedirectLoggedIn: Redirects logged-in users to Dashboard for public routes
  const RedirectLoggedIn = ({ children }: any) => {
    return credentials ? <Navigate to="/Dashboard" /> : children;
  };

  return (
    <CredentailsContext.Provider
      // @ts-ignore
      value={{ storedCredentails, setStoredCredentails }}
    >
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <RedirectLoggedIn>
                <HomeScreen />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/whatIsTokenization"
            element={
              <RedirectLoggedIn>
                <WhatIsTokenizationScreen />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/tokenizewithus"
            element={
              <RedirectLoggedIn>
                <TokenizeWithUs />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/emailsent"
            element={
              <RedirectLoggedIn>
                <EmailSentScreen />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/SignUpScreen"
            element={
              <RedirectLoggedIn>
                <SignUpScreen />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/VerifyEmailScreen"
            element={
              <RedirectLoggedIn>
                <VerifyEmailScreen />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/SignUpScreen2"
            element={
              <RedirectLoggedIn>
                <SignUpScreen2 />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/CreatePasswordScreen"
            element={
              <RedirectLoggedIn>
                <CreatePassword />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/LoginScreen"
            element={
              <RedirectLoggedIn>
                <LoginScreen />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/ForgotPassword"
            element={
              <RedirectLoggedIn>
                <ForgotPassword />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/FpVerifyEmailScreen"
            element={
              <RedirectLoggedIn>
                <VerifyEmailScreen2 />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/ResetPassword"
            element={
              <RedirectLoggedIn>
                <ResetPassword />
              </RedirectLoggedIn>
            }
          />
          <Route
            path="/PasswordResetSuccess"
            element={
              <RedirectLoggedIn>
                <PasswordResetSuccess />
              </RedirectLoggedIn>
            }
          />
          {/* Protected Routes */}
          <Route
            path="/Dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/Project"
            element={
              <PrivateRoute>
                <ProjectScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/ListProjectPromtScreen"
            element={
              <PrivateRoute>
                <ListProjectPromtScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/AccountCreatedScreen"
            element={
              <PrivateRoute>
                <AccountCreatedScreen />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </CredentailsContext.Provider>
  );
}

export default App;
